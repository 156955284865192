import React from 'react';
import { useTranslation } from 'react-i18next';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'; // Slider stil dosyaları
import mainImage from '../assets/IB_main.png'; // Resmin doğru yolu
function IB() {
  const { t } = useTranslation();

  return (

    <div>

      <div className="empty-space"></div>
      <div className="empty-space_init"></div>
      <section className="pt-2 pb-2 news">
        <div class="btn-toolbar d-flex justify-content-lg-around" role="toolbar" >
          <div class="btn-group button-width-top" role="group" aria-label="Third group">
            <button type="button" class="btn btn-purple-moon btn-rounded" onClick={() => window.open(process.env.PUBLIC_URL + '/pdfs/11 SINIF IB BİLİM PDF-2.pdf', '_blank')}>{t('IB HL-SL MATEMATİK ve FİZİK')}</button>
          </div>
          <div class="btn-group button-width-top" role="group">
            <button type="button" class="btn btn-purple-moon btn-rounded" onClick={() => window.open(process.env.PUBLIC_URL + '/pdfs/12 SINIF IB BİLİM PDF-4.pdf', '_blank')}>{t('IB, A-LEVEL, AP ve SAT')}</button>
          </div>
          <div class="btn-group button-width-top" role="group">
            <button type="button" class="btn btn-purple-moon btn-rounded" onClick={() => window.open(process.env.PUBLIC_URL + '/pdfs/11 SINIF IB BİLİM PDF-3.pdf', '_blank')}>{t('IB HAKKINDA')}</button>
          </div>
        </div>
      </section>
      <section data-type="component-text">

        <section className="container pt-5 pb-5">
          <div className="row">
            <div className="col-lg-6 col-sm-12 mb-5">
              <h1 className="pb-3 text-center">{t('ib_header')}</h1>
              <div className="text-list">
                <p>{t('ib_main_1')}</p>
                <p>{t('ib_main_2')}</p>
                <p>{t('ib_main_3')}</p>
                <p>{t('ib_main_4')}</p>
                <p>{t('ib_main_5')}</p>
                <p>{t('ib_main_6')}</p>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 d-flex justify-content-center align-items-center">
              <img src={mainImage} alt="MatBilim Logo" className="img-fluid mx-auto d-block full-width-image" />
            </div>
          </div>
        </section>

      </section>

    </div>
  );
}

export default IB;
