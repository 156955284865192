import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'; // Slider stil dosyaları
import slider1 from '../assets/alevel.png';
import slider1_Mobile from '../assets/alavel-Mobile.png';
function ALevel() {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Ekran boyutunu kontrol eden bir fonksiyon
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Mobil için 768px sınırını kullanıyoruz
    };

    // İlk yükleme sırasında kontrol et
    handleResize();
    // Ekran boyutu değişikliklerini dinle
    window.addEventListener('resize', handleResize);

    // Temizlik işlemi
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (

    <div>

      <div>
        <img src={isMobile ? slider1_Mobile : slider1} alt="Slide 1" className="img-fluid" />
      </div>
      <section className="pt-2 pb-2 news">
        <div class="btn-toolbar d-flex justify-content-lg-around" role="toolbar" >
          <div class="btn-group button-width-top" role="group" aria-label="Third group">
            <button type="button" class="btn btn-purple-moon btn-rounded" onClick={() => window.open(process.env.PUBLIC_URL + '/pdfs/11 SINIF IB BİLİM PDF-6.pdf', '_blank')}>{t('A-LEVEL PROGRAMI')}</button>
          </div>
          <div class="btn-group button-width-top" role="group">
            <button type="button" class="btn btn-purple-moon btn-rounded" onClick={() => window.open(process.env.PUBLIC_URL + '/pdfs/12 SINIF IB BİLİM PDF-4.pdf', '_blank')}>{t('IB, A-LEVEL, AP ve SAT')}</button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ALevel;
