import ataturk1 from "../assets/ataturk1.png";
import ataturk2 from "../assets/ataturk2.png";
import ataturk3 from "../assets/ataturk3.png";
import ataturk4 from "../assets/ataturk4.png";
import ataturk5 from "../assets/ataturk5.png";
const ataturkData = [
    {
      image: ataturk1,
      alt: "Atatürk 1",
      quote: "Bilim deyince, onda hakikat diye öne sürdüğü önermelerin pekin olmasını ister; pekinlik ise en mükemmel şekliyle matematikte bulunur. O halde bilim o disiplindir ki; önermeleri matematikle ifade edilir. O zaman matematiği kullanmayan disiplinler bilimin dışında kalacaklardır.",
      author: "Mustafa Kemal Atatürk",
    },
    {
      image: ataturk2,
      alt: "Atatürk 2",
      quote: "Okul sayesinde, okulun vereceği ilim ve fen sayesindedir ki, Türk milleti, Türk sanatı, Türk ekonomisi, Türk şiir ve edebiyatı bütün güzellikleriyle gelişir.",
      author: "Mustafa Kemal Atatürk",
    },
    {
      image: ataturk3,
      alt: "Atatürk 3",
      quote: "Milli eğitimde süratle yüksek bir seviyeye çıkacak olan bir milletin, hayat mücadelesinde maddi ve manevi bütün kudretlerinin artacağı muhakkaktır.",
      author: "Mustafa Kemal Atatürk",
    },
    {
      image: ataturk4,
      alt: "Atatürk 4",
      quote: "Eğitimdir ki, bir milleti ya özgür, bağımsız, şanlı, yüksek bir topluluk halinde yaşatır; ya da esaret ve sefalete terk eder.",
      author: "Mustafa Kemal Atatürk",
    },
    {
      image: ataturk5,
      alt: "Atatürk 5",
      quote: "İlim ve fen çalışmalarının merkezi okuldur. Bundan dolayı okul lazımdır. Okul adını hep beraber hürmetle, saygıyla analım.",
      author: "Mustafa Kemal Atatürk",
    },
  ];
  
  export default ataturkData;