import React, { useState, useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'; // Slider stil dosyaları
import './Secondaryschool.css'; // Home.css dosyasını dahil ediyoruz
import slider1 from '../../assets/secondaryschool_main.png';
import slider1_Mobile from '../../assets/secondaryschool_main-Mobile.png';
import lgs1 from '../../assets/lgs1.png';
import lgs2 from '../../assets/lgs2.png';
import lgs3 from '../../assets/lgs3.png';
import lgs4 from '../../assets/lgs4.png';
import lgs5 from '../../assets/lgs5.png';
import lgs6 from '../../assets/lgs6.png';
import lgs7 from '../../assets/lgs7.png';
import lgs1_Mobile from '../../assets/lgs1-Mobile.png';
import lgs2_Mobile from '../../assets/lgs2-Mobile.png';
import lgs3_Mobile from '../../assets/lgs3-Mobile.png';
import lgs4_Mobile from '../../assets/lgs4-Mobile.png';
import lgs5_Mobile from '../../assets/lgs5-Mobile.png';
import lgs6_Mobile from '../../assets/lgs6-Mobile.png';
import lgs7_Mobile from '../../assets/lgs7-Mobile.png';
function Secondaryschool() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Ekran boyutunu kontrol eden bir fonksiyon
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Mobil için 768px sınırını kullanıyoruz
    };

    // İlk yükleme sırasında kontrol et
    handleResize();
    // Ekran boyutu değişikliklerini dinle
    window.addEventListener('resize', handleResize);

    // Temizlik işlemi
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (

    <div>

        <div>
          <img src={isMobile ? slider1_Mobile : slider1} alt="Slide 1" className="img-fluid" />
        </div>
      <div>
          <img src={isMobile ? lgs1_Mobile : lgs1} alt="Slide 1" className="img-fluid" />
        </div>
        <div>
          <img src={isMobile ? lgs2_Mobile : lgs2} alt="Slide 2" className="img-fluid" />
        </div>
        <div>
          <img src={isMobile ? lgs3_Mobile : lgs3} alt="Slide 3" className="img-fluid" />
        </div>
        <div>
          <img src={isMobile ? lgs4_Mobile : lgs4} alt="Slide 4" className="img-fluid" />
        </div>
        <div>
          <img src={isMobile ? lgs5_Mobile : lgs5} alt="Slide 5" className="img-fluid" />
        </div>
        <div>
          <img src={isMobile ? lgs6_Mobile : lgs6} alt="Slide 6" className="img-fluid" />
        </div>
        <div>
          <img src={isMobile ? lgs7_Mobile : lgs7} alt="Slide 7" className="img-fluid" />
        </div>
      {/* <section data-type="component-text">

        <section className="container pt-5 pb-5">

          <div className="row">
            <div className="col-lg-6 col-sm-12 mb-5">
              <h2 className="pb-3 text-center">{t('secondaryschool')}</h2>
              {/* <p><strong>{t('secondaryschool_main')}</strong></p>
              <p>{t('secondaryschool_main_1')}</p>
              <p>{t('secondaryschool_main_2')}</p>
              <p>{t('secondaryschool_main_3')}</p>
              <p>{t('secondaryschool_main_4')}</p>
              <p>{t('secondaryschool_main_5')}</p>
              {/* <p>{t('home_line_3')}</p>
              <p>{t('home_line_3')}</p>
              <p>{t('home_line_3')}</p>
              <p>{t('home_line_3')}</p>
              <p>{t('home_line_3')}</p> 
            </div>

            <div className="col-lg-6 col-sm-12">
              <img src={secondaryschool_sub_main} alt="MatBilim Logo" className="img-fluid" />
            </div>
          </div>
        </section>
      </section> */}
      {/* <FixedFooter>
        <section className="pt-2 pb-2 news">
          <div className="d-lg-none">
            <button
              className="btn btn-purple-moon btn-rounded w-100"
              data-bs-toggle="collapse"
              data-bs-target="#mobileMenu"
              aria-expanded="false"
              aria-controls="mobileMenu"
            >
              {t('menu')}
            </button>
            <div id="mobileMenu" className="collapse">
              <div className="btn-toolbar d-flex flex-column" role="toolbar">
                <div className="btn-group button-width" role="group">
                  <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/secondaryschool/preparation_program')}>{t('preparation_program')}</button>
                </div>
                <div className="btn-group button-width" role="group">
                  <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/secondaryschool/our_system')}>{t('our_system')}</button>
                </div>
                <div className="btn-group button-width" role="group">
                  <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/secondaryschool/foreign_language')}>{t('foreign_language')}</button>
                </div>
                <div className="btn-group button-width" role="group">
                  <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/secondaryschool/clubs')}>{t('clubs')}</button>
                </div>
                <div className="btn-group button-width" role="group">
                  <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/secondaryschool/counselling')}>{t('counselling')}</button>
                </div>
                <div className="btn-group button-width" role="group">
                  <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/secondaryschool/gallery')}>{t('gallery')}</button>
                </div>
              </div>
            </div>
          </div>

          <div className="d-none d-lg-flex btn-toolbar justify-content-lg-around" role="toolbar">
            <div className="btn-group button-width" role="group">
              <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/secondaryschool/preparation_program')}>{t('preparation_program')}</button>
            </div>
            <div className="btn-group button-width" role="group">
              <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/secondaryschool/our_system')}>{t('our_system')}</button>
            </div>
            <div className="btn-group button-width" role="group">
              <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/secondaryschool/foreign_language')}>{t('foreign_language')}</button>
            </div>
            <div className="btn-group button-width" role="group">
              <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/secondaryschool/clubs')}>{t('clubs')}</button>
            </div>
            <div className="btn-group button-width" role="group">
              <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/secondaryschool/counselling')}>{t('counselling')}</button>
            </div>
            <div className="btn-group button-width" role="group">
              <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/secondaryschool/gallery')}>{t('gallery')}</button>
            </div>
          </div>
        </section>
      </FixedFooter> */}

    </div>
  );
}

export default Secondaryschool;
