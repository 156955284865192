import React, { useState, useEffect } from 'react';
import Slider from 'react-slick'; // react-slick slider kütüphanesi
import { useTranslation } from 'react-i18next';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'; // Slider stil dosyaları
import './PrepareOverseas.css'; // Home.css dosyasını dahil ediyoruz
import slider1 from '../../assets/prepareoverseas.png';
import slider1_Mobile from '../../assets/primary_main-Mobile.png';
import { useNavigate } from 'react-router-dom';
import FixedFooter from '../../components/FixedFooter';
function PrepareOverseas() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Ekran boyutunu kontrol eden bir fonksiyon
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Mobil için 768px sınırını kullanıyoruz
    };

    // İlk yükleme sırasında kontrol et
    handleResize();
    // Ekran boyutu değişikliklerini dinle
    window.addEventListener('resize', handleResize);

    // Temizlik işlemi
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Slider ayarları
  const settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: true
  };
  return (

    <div>

      <Slider {...settings} className="slider-container">
        <div>
        <img src={isMobile ? slider1_Mobile : slider1} alt="Slide 1" className="img-fluid" />
        </div>
      </Slider>
      <section data-type="component-text">
        <section className="container pt-5 pb-5">
          <div className="row">
            <div className="col-lg-12 col-sm-12 mb-5">
              <h4>{t('prepareoverseas_main_1')}</h4>
              <br></br>
              <h4>{t('prepareoverseas_main_2')}</h4>
              <br></br>
              <h4>{t('prepareoverseas_main_3')}</h4>
            </div>

          </div>
        </section>
      </section>
      <FixedFooter>
  <section className="pt-2 pb-2 news">
    <div className="d-lg-none">
      {/* Mobil görünüm için dropdown */}
      <button
        className="btn btn-purple-moon btn-rounded w-100"
        data-bs-toggle="collapse"
        data-bs-target="#mobileMenu"
        aria-expanded="false"
        aria-controls="mobileMenu"
      >
        {t('menu')}
      </button>
      <div id="mobileMenu" className="collapse">
        <div className="btn-toolbar d-flex flex-column" role="toolbar">
          <div className="btn-group button-width" role="group">
            <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/')}>{t('country_1')}</button>
          </div>
          <div className="btn-group button-width" role="group">
            <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/')}>{t('country_2')}</button>
          </div>
          <div className="btn-group button-width" role="group">
            <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/')}>{t('country_3')}</button>
          </div>
          <div className="btn-group button-width" role="group">
            <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/')}>{t('country_4')}</button>
          </div>
          <div className="btn-group button-width" role="group">
            <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/')}>{t('country_5')}</button>
          </div>
          <div className="btn-group button-width" role="group">
            <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/')}>{t('country_6')}</button>
          </div>
          <div className="btn-group button-width" role="group">
            <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/')}>{t('country_7')}</button>
          </div>
          <div className="btn-group button-width" role="group">
            <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/')}>{t('country_8')}</button>
          </div>
          <div className="btn-group button-width" role="group">
            <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/')}>{t('country_9')}</button>
          </div>
          <div className="btn-group button-width" role="group">
            <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/')}>{t('country_10')}</button>
          </div>

        </div>
      </div>
    </div>

    {/* Masaüstü görünüm */}
    <div className="d-none d-lg-flex btn-toolbar justify-content-lg-around" role="toolbar">
      <div className="btn-group button-width-19" role="group">
        <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/')}>{t('country_1')}</button>
      </div>
      <div className="btn-group button-width-19" role="group">
        <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/')}>{t('country_2')}</button>
      </div>
      <div className="btn-group button-width-19" role="group">
        <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/')}>{t('country_3')}</button>
      </div>
      <div className="btn-group button-width-19" role="group">
        <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/')}>{t('country_4')}</button>
      </div>
      <div className="btn-group button-width-19" role="group">
        <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/')}>{t('country_5')}</button>
      </div>
      <div className="btn-group button-width-19" role="group">
        <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/')}>{t('country_6')}</button>
      </div>
      <div className="btn-group button-width-19" role="group">
        <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/')}>{t('country_7')}</button>
      </div>
      <div className="btn-group button-width-19" role="group">
        <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/')}>{t('country_8')}</button>
      </div>
      <div className="btn-group button-width-19" role="group">
        <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/')}>{t('country_9')}</button>
      </div>
      <div className="btn-group button-width-19" role="group">
        <button type="button" className="btn btn-purple-moon btn-rounded" onClick={() => navigate('/')}>{t('country_10')}</button>
      </div>

    </div>
  </section>
</FixedFooter>

    </div>
  );
}

export default PrepareOverseas;
