// Sidebar.js
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './sidebar.css';

function Sidebar({ isOpen, toggleSidebar }) {
  const { t } = useTranslation();
  const sidebarRef = useRef();


  return (
    <div ref={sidebarRef} className={`sidebar ${isOpen ? 'open' : ''}`}>

      <ul>
        <li>
          <Link className="nav-link" to="/." onClick={toggleSidebar}>{t('home')}</Link>
        </li>
        <li>
          <Link className="nav-link" to="/corporate" onClick={toggleSidebar}>{t('corporate_menu')}</Link>
        </li>
        <li>
          <Link className="nav-link"  onClick={() => {window.open(process.env.PUBLIC_URL + '/pdfs/12 SINIF IB BİLİM PDF-2.pdf'); toggleSidebar();}} >{t('university_preparation')}</Link>
        </li>
        <li>
          <Link className="nav-link" to="/algorithm" onClick={toggleSidebar}>{t('algorithm')}</Link>
        </li>
        <li>
          <Link className="nav-link" to="/prepareoverseas" onClick={toggleSidebar}>{t('prepare_overseas')}</Link>
        </li>
        <li>
          <Link className="nav-link" to="/ib" onClick={toggleSidebar}>{t('eleventh_pdf_1')}</Link>
        </li>
        <li>
          <Link className="nav-link" to="/alevel" onClick={toggleSidebar}>{t('eleventh_pdf_4')}</Link>
        </li>
        <li>
          <Link className="nav-link"  onClick={() => {window.open(process.env.PUBLIC_URL + '/pdfs/12 SINIF IB BİLİM PDF-6.pdf'); toggleSidebar();}} >{t('AP')}</Link>
        </li>
        <li>
          <Link className="nav-link" to="/secondaryschool" onClick={toggleSidebar}>{t('lgs')}</Link>
        </li>
        {/* <li>
          <Link className="nav-link" to="/highschool" onClick={toggleSidebar}>{t('bilim_insani')}</Link>
        </li> */}
        <li>
          <Link className="nav-link" to="/GCSE" onClick={toggleSidebar}>{t('GCSE')}</Link>
        </li>
        <li>
          <Link className="nav-link" to="/highschool" onClick={toggleSidebar}>{t('highschool')}</Link>
        </li>
        <li>
          <Link className="nav-link"  onClick={() => {window.open(process.env.PUBLIC_URL + '/pdfs/12 SINIF IB BİLİM PDF-5.pdf'); toggleSidebar();}} >{t('IELTS')}</Link>
        </li>
        <li>
          <Link className="nav-link"  onClick={() => {window.open(process.env.PUBLIC_URL + '/pdfs/12 SINIF IB BİLİM PDF-7.pdf'); toggleSidebar();}} >{t('SAT')}</Link>
        </li>
        <li>
          <Link className="nav-link" to="/achievement" onClick={toggleSidebar}>{t('achievement')}</Link>
        </li>
        <li>
          <Link className="nav-link" to="/highschool/counselling" onClick={toggleSidebar}>{t('counselling')}</Link>
        </li>
        <li>
          <Link className="nav-link" onClick={toggleSidebar} to="/scholarship">{t('scholarship')}</Link>
        </li>
        <li>
          <Link className="nav-link" onClick={toggleSidebar} to="/our_staff">{t('our_staff')}</Link>
        </li>
        <li>
          <Link className="nav-link" to="/Ataturk" onClick={toggleSidebar}>{t('the_Atatürk_corner')}</Link>
        </li>
        <li>
          <Link className="nav-link" to="/contact" onClick={toggleSidebar}>{t('contact')}</Link>
        </li>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </ul>
    </div>
  );
}

export default Sidebar;
