import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import sliderEn from '../assets/under_construction.png';
import sliderTr from '../assets/yapim_asamasinda.png';
import slider_Mobile from '../assets/yapim_asamasinda-Mobile.png';

function Preschool() {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const sliderImage = currentLanguage === 'tr' ? sliderTr : sliderEn;

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Ekran boyutunu kontrol eden bir fonksiyon
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Mobil için 768px sınırını kullanıyoruz
    };

    // İlk yükleme sırasında kontrol et
    handleResize();
    // Ekran boyutu değişikliklerini dinle
    window.addEventListener('resize', handleResize);

    // Temizlik işlemi
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Slider ayarları
  const settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: true
  };

  return (
    <div>
      <Slider {...settings} className="slider-container">
        <div>
        <img src={isMobile ? slider_Mobile : sliderImage} alt="Slide" className="img-fluid" />
        </div>
      </Slider>
    </div>
  );
}

export default Preschool;
